import { useState, useEffect } from "react";
import TreeItem from '@material-ui/lab/TreeItem';
import Sticky from 'react-sticky-el';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import TreeView from "@material-ui/lab/TreeView";



import setPrivilage from "./../../../images/Guide/setPrivilages.jpg";

export default function ProductTypes() {
    const [currentSubItem, setCurrentSubItem] = useState(null);


    useEffect(() => {

        if (currentSubItem)
            scroller.scrollTo(currentSubItem)
        else
            scroll.scrollToTop()

    });


    return (
        <div>
            <div className="row">
                <div className="col-sm-9 pb-5 mb-5">
                    <Element name="Setup">
                        <h2 style={{ marginBottom: "50px" }}>{window.locale.translate("Types Of Products")}</h2>
                        {window.locale.translate("The application is based on the following types of products.")}
                    </Element>

                    <Element name="RawMaterials" className="mt-5">
                        <h4 style={{ marginBottom: "25px" }}>{window.locale.translate("Raw Materials")}</h4>
                        {window.locale.translate("In eDistillery, Raw materials are the products that can be purchased and used in distillations and bulk productions. ")}
                        <br /><br />
                        {window.locale.translate("Examples:")}
                        <br />
                        <br />
                        {window.locale.translate("Sugar")}
                        <br />
                        {window.locale.translate("Sugarcane/Molasses")}
                        <br />
                        {window.locale.translate("Grains")}
                        <br />
                        {window.locale.translate("Fruits")}
                        <br />
                        {window.locale.translate("Chocolates")}
                        <br /><br />
                        {window.locale.translate("Unit Types")}
                        <br /><br />
                        {window.locale.translate("The units supported are:")}
                        <br />
                        {window.locale.translate("Unit: For every raw ingredient that has its own unit for measurement")}
                        <br />
                        {window.locale.translate("Litres: For any raw integrient that is measured in millilitres or litres regardless of whether its an alcoholic item or not.")}
                        <br />
                        <br />
                        {window.locale.translate("When purchasing an item with litres as unit type, eDistillery is flexible in regards of whether you put it in a container in eDistillery or just enter the quantity in Litres for what is purchased.")}
                        <br />
                        <br />
                        {window.locale.translate("It is highly recommended to record alcoholic raw materials in containers in eDistillery so they can be tracked.")}
                        <br />
                        <br />
                        {window.locale.translate("This is because ATO only requires the identification of storage containers that has contained alcohol.")}

                    </Element>

                    <Element name="Distillates" className="mt-5">
                        <h4 style={{ marginBottom: "25px" }}>{window.locale.translate("Distillates")}</h4>
                        {window.locale.translate("A distillate is a product for which its been distilled. This means the label of the final product that you will be selling.")}
                        <br />
                        <br />
                        {window.locale.translate("Examples:")}
                        <br />
                        <br />
                        {window.locale.translate("Raspberry Gin Distillate: If your bottle is labelled as Raspberry Gin.")}
                        <br />
                        {window.locale.translate("Absolute Pineapple Vodka Distillate: If your bottle is labelled as Absolute Pineapple Vodka.")}
                        <br /><br />
                        {window.locale.translate("Types")}
                        <br /><br />
                        {window.locale.translate("Type of a distillate can be Vodka, Gin, Whiskey. Feel free to set it yourself as you know what you are making :)")}
                        <br /><br />
                    </Element>

                    <Element name="BulkProducts" className="mt-5">
                        <h4 style={{ marginBottom: "25px" }}>{window.locale.translate("Bulk Products")}</h4>
                        {window.locale.translate("A Bulk Product refers to a product formed by combining various items or by adding additional ingredients post-production to impart flavor. These can be named accordingly, incorporating the final product's name, such as Hazelnut Macerate, Strawberry Gin, Lemon Macerate.")}
                        <br /><br />
                        {window.locale.translate("Types")}
                        <br /><br />
                        {window.locale.translate("Types of bulk products include Liquor, Macerate, Whiskey, Vodka and are not limited to these only.")}
                    </Element>

                    <Element name="ImportedProducts" className="mt-5">
                        <h4 style={{ marginBottom: "25px" }}>{window.locale.translate("Ready Made Spirits")}</h4>
                        {window.locale.translate("Imported products are the ready made spirits that you can purchase in bulk and use either in bulk production of your own product or you can directly bottle them or send it to vendors for bottling purpose.")}
                    </Element>

                    <Element name="FinishedProducts" className="mt-5">
                        <h4 style={{ marginBottom: "25px" }}>{window.locale.translate("Finished Products")}</h4>
                        {window.locale.translate("Finished products are the packaged bottles/cans of the product you have manufactured and are ready to be dispatched.")}
                    </Element>


                </div>
                <div className="col-sm-3 pr-3">
                    <div className="">
                        <Sticky>
                            <TreeView>
                                <TreeItem nodeId="1" label="Raw Materials" onClick={() => setCurrentSubItem("RawMaterials")} />
                                <TreeItem nodeId="2" label="Distillates" onClick={() => setCurrentSubItem("Distillates")} />
                                <TreeItem nodeId="3" label="Bulk Products" onClick={() => setCurrentSubItem("BulkProducts")} />
                                <TreeItem nodeId="4" label="Ready Made Spirits" onClick={() => setCurrentSubItem("ImportedProducts")} />
                                <TreeItem nodeId="5" label="Finished Products" onClick={() => setCurrentSubItem("FinishedProducts")} />
                            </TreeView>
                        </Sticky>
                    </div>
                </div>
            </div>
        </div>
    )


}